<div style="color: black">
  <div *ngIf="showProgressBar">
    <div class="progress" *ngFor="let progress of getProgressBars()">
      <div
        class="progress-bar"
        role="progressbar"
        [ngStyle]="{ width: progressBars[progress] + '%' }"
      ></div>
    </div>
  </div>
  <div>
    <div class="center-block" *ngIf="!uploadFolder">
      <div
        ngfDrop
        selectable="1"
        (click)="onOpenedFolder()"
        (drop)="onDroppedFile()"
        [(validDrag)]="validComboDrag"
        [(files)]="files"
        [(lastInvalids)]="lastInvalids"
        accept="{{ fileAccept }}"
        class="well my-drop-zone"
        [class.invalid-drag]="validComboDrag === false"
        [class.valid-drag]="validComboDrag"
        (filesChange)="getFiles(files)"
      >
        <p class="mb0" *ngIf="showDescription">Drag & Drop</p>
        <p class="mb0" *ngIf="showDescription">
          Drag and drop any {{ fileText }} or <a>click here to Upload</a>
        </p>
        <p *ngIf="!showDescription">Drag and drop or <a>click here to Upload</a></p>
      </div>
    </div>
    <div class="center-block" *ngIf="uploadFolder">
      <div class="well my-drop-zone" (click)="folderInput.click()">
        <input
          style="display: none"
          #folderInput
          type="file"
          (change)="getFiles(folderInput.files)"
          webkitDirectory
          mozdirectory
        />
        <p class="mb0" *ngIf="showDescription">
          Click <a>here</a> to upload a folder that contains WAV, AIF or MP3 files
        </p>
        <p class="mb0" *ngIf="showDescription">(Make sure is a folder and not a file)</p>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FileService } from 'sostereo-services';
import { forEach, map } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { TrackingService } from '../../services/tracking.service';
import { CommonModule } from '@angular/common';
import { ngfModule } from 'angular-file';

declare const window;

@Component({
  standalone: true,
  imports: [CommonModule, ngfModule],
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @ViewChild('folderInput') folderInput;
  @Input() fileType;
  @Input() fileAccept;
  @Input() uploadFolder = false;
  @Input() uploadToken;
  @Input() fileText;
  @Input() showDescription = true;
  @Input() showProgressBar;
  @Input() privateFile: boolean = false;
  public fileOwnerId;
  @Output() openedFolder = new EventEmitter();
  @Output() fileDropped = new EventEmitter();
  @Output() tracks = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() error = new EventEmitter();
  private filesArray: any = [];
  files: File[] = [];
  progressBars: any[] = [];
  httpEmitter: Subscription;
  offline: any;
  validComboDrag;
  lastInvalids: any[] = [];

  constructor(
    private fileService: FileService,
    private toastr: ToastrService,
    private trackingService: TrackingService,
  ) {}

  cancel() {
    this.progressBars = [];
    if (this.httpEmitter) {
      this.httpEmitter.unsubscribe();
    }
  }

  uploadFiles(files: File[]) {
    window.addEventListener('offline', (e) => {
      this.offline = true;
      this.toastr.error('', 'Internet Connection Lost');
      console.log('Internet Connection Lost', e);
      this.trackingService.track('Internet Connection Lost', {});
    });
    window.addEventListener('online', (e) => {
      if (this.offline) {
        this.toastr.success('', `Internet Connection Recovered`);
        console.log('Internet Connection Recovered', e);
        this.trackingService.track('Internet Connection Recovered', {});
      }
      this.offline = false;
    });
    this.toastr.info('', 'We are uploading your files');
    try {
      forEach(files, (file, index) => {
        if (file.name.startsWith('.')) {
          return;
        }
        const extension = file.name.substring(file.name.lastIndexOf('.'), file.name.length);
        if (!this.fileType.split(',').includes(extension)) {
          this.toastr.error('', `Not valid file ${file.name}`);
          return;
        }
        this.createFile(file, index);
      });
    } catch (error) {
      console.log('catch e', error);
    }
    this.files = [];
    this.progressBars = [];
  }

  public createFile(file, index) {
    const upload = {
      name: file.name,
      uploadToken: this.uploadToken,
      userId: this.fileOwnerId,
      metadata: { pubNubChannel: this.fileOwnerId, folderPath: file.webkitRelativePath },
      status: 'published',
    };
    this.progressBars[`${file.name}-${index}`] = 0;
    // Validation to add same folder's files if they are not already processed
    if (
      file.webkitRelativePath === '' ||
      !map(this.filesArray, 'webkitRelativePath').includes(file.webkitRelativePath)
    ) {
      this.filesArray.push(file);
      this.fileService.createFile(upload).subscribe({
        next: (res) => {
          this.uploadFileAWSS3(res.data, file, index);
        },
        error: (err) => {
          console.error('Error at createFile >>>> ', err);
          this.tracks.emit({
            fileName: file.fileName,
            fileUrls: file.fileUrl,
            fileId: file.fileId,
            filePath: file.webkitRelativePath,
            progressStatus: { label: 'Error: Could not upload', id: 'error' },
          });
          const errObj = {
            fileId: file.fileId,
            error: err,
            fileName: file.name,
            createdFile: file,
          };
          this.error.emit(errObj);
          this.trackingService.track('Create file error', {
            errorStatus: err?.statusText + ' - ' + err?.status,
            errorMessage: err?.message,
            fileData: errObj,
          });
        },
      });
    }
  }

  public uploadFileAWSS3(createdFile, file, index) {
    this.fileService
      .uploadFileAWSS3(createdFile.url, file, createdFile.fields, this.privateFile, true)
      .subscribe((uploadRes: any) => {
        switch (uploadRes.type) {
          case 'start':
            this.progressBars[`${file.name}-${index}`] = 0;
            this.tracks.emit({
              fileId: createdFile.fileId,
              fileName: createdFile.fileName,
              progress: this.progressBars[`${file.name}-${index}`]
                ? this.progressBars[`${file.name}-${index}`]
                : 0,
            });
            break;
          case 'progress':
            this.progressBars[`${file.name}-${index}`] = (uploadRes.loaded * 100) / uploadRes.total;
            this.tracks.emit({
              fileId: createdFile.fileId,
              fileName: createdFile.fileName,
              progress: this.progressBars[`${file.name}-${index}`]
                ? this.progressBars[`${file.name}-${index}`]
                : 0,
            });
            break;
          case 'success':
            this.tracks.emit({
              fileName: createdFile.fileName,
              fileUrls: createdFile.fileUrl,
              fileId: createdFile.fileId,
              key: createdFile?.fields?.key,
              filePath: file.webkitRelativePath,
              progressStatus: { label: 'Successfully loaded!', id: 'success' },
            });
            delete this.progressBars[`${file.name}-${index}`];

            break;
          case 'error':
            const response: any = uploadRes;
            const err = response?.err || uploadRes?.type === 'error' ? uploadRes : null;
            if (err) {
              this.handleErrorAWS(err, createdFile, file, index);
            }
            break;
        }
      });
  }

  handleErrorAWS(err: any, createdFile: any, file: any, index: any) {
    const errObj: any = {
      fileId: createdFile.fileId,
      error: err,
      fileName: file.name,
      createdFile: createdFile,
      uploadedFile: file,
    };
    if (err && err.status !== 0) {
      errObj.displayMessage = true;
      // Show error and leave song with retry option or delete option for the users
      this.trackingService.track('uploadfileAWSS3 error', {
        errorStatus: err?.statusText + ' - ' + err?.status,
        errorMessage: err?.message,
        fileData: errObj,
        ...err,
      });
    } else if (this.offline) {
      console.log('Internet Connection Error', err);
      this.trackingService.track('Internet Connection error', {
        errorStatus: err?.statusText + ' - ' + err?.status,
        errorMessage: err?.message,
        fileData: errObj,
        ...err,
      });
    } else {
      console.log('Unknown upload Error', err);
      this.trackingService.track('Unknownd upload uploadfileAWSS3 error', {
        errorStatus: err?.statusText + ' - ' + err?.status,
        errorMessage: err?.message,
        fileData: errObj,
        ...err,
      });
    }
    delete this.progressBars[`${file.name}-${index}`];
    this.error.emit(errObj);
  }

  ngOnInit() {
    this.fileAccept = this.fileAccept || 'audio/*';
  }

  getFiles(files) {
    if (this.offline) {
      this.toastr.error('', 'Internet Connection Lost');
    } else {
      this.uploadFiles(files);
    }
    if (this.folderInput) {
      this.folderInput.nativeElement.value = '';
    }
  }

  onOpenedFolder() {
    this.openedFolder.emit();
  }

  onDroppedFile() {
    if (this.lastInvalids?.length > 0) {
      this.toastr.error(`Only files with the following extensions are allowed: ${this.fileType}`);
    }
    this.fileDropped.emit();
  }

  removeFileFromArray(file) {
    const idx = this.filesArray.findIndex(
      (f) =>
        f.webkitRelativePath === file.tableRow.filePath ||
        (file.tableRow.uploadedFile &&
          f.webkitRelativePath === file.tableRow.uploadedFile.webkitRelativePath),
    );
    if (idx !== -1) {
      this.filesArray.splice(idx, 1);
    }
  }

  getProgressBars() {
    return Object.keys(this.progressBars);
  }
}
